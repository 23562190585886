import React from 'react';
// import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import withRouter from 'react-router/withRouter';
import loadData from '../franchise_page_stage_manager';

import loadable from '@loadable/component';

const NotFound = loadable(() =>
  import('../../../../components/not_found_component/not_found_component')
);
const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);
const QuickCarousel = loadable(
  () => import('../../../../components/QuickCarousel/QuickCarousel'),
  {
    resolveComponent: components => components.QuickCarousel
  }
);
const StickyBar = loadable(
  () => import('../../../../components/StickyBar/StickyBar'),
  {
    resolveComponent: components => components.StickyBar
  }
);
const Section = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Section
  }
);
const Text = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Text
  }
);
const Image = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Image
  }
);
const CategoryAndArticles = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.CategoryAndArticles
  }
);
const AdContainer = loadable(() =>
  import('../../../../components/ad/AdContainer')
);

/**
 * Container element for custom styling
 */
const PackageContainer = ({ children }) => (
  <div id="franchisePage__nextBigThings">{children}</div>
);
PackageContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const NextBigThingsLayout = props => {
  // const [categories, setCategories] = useState(get(props, 'franchisePage.externalList', []));
  const fullPackageObj = get(props, 'franchisePage', null);
  const fullPackageData = get(fullPackageObj, 'data', {});
  const categories = get(fullPackageData, 'externalList.List', []);
  const franchiseBlurb = get(fullPackageData, 'acf.franchise_blurb', '');

  const featureImageDesktop = get(
    fullPackageData,
    'acf.franchise_images.franchise_hero_image_desktop'
  );

  const featureImageMobile = get(
    fullPackageData,
    'acf.franchise_images.franchise_hero_image_mobile'
  );

  const blurbImage = get(
    fullPackageData,
    'acf.franchise_images.franchise_blurb_image'
  );

  const packageTitle = get(
    fullPackageData,
    'title.rendered',
    'Next Big Things In Tech'
  );
  const packageExcerpt = get(
    fullPackageData,
    'excerpt.rendered',
    'These companies are the next big things in technology.'
  );

  const carousel = get(
    fullPackageData,
    'acf.best_workplaces_for_innovators.carousel',
    []
  );

  const contributorsBlock = get(
    fullPackageData,
    'acf.franchiseContributors',
    []
  );

  const reduceExternalData = data => {
    const finalArray = data.reduce((acc, item) => {
      if (item.Category in acc) {
        acc[item.Category] = [...acc[item.Category], item];
      } else {
        acc[item.Category] = [item];
      }
      return acc;
    }, {});

    return finalArray;
  };

  const interstitial = (
    <div className="interstitialUnitWrapper">
      <AdContainer type="break" />
    </div>
  );

  // const fetchFranchiseList = () => {
  //   axios.get(packageExternalDataUrl).then(({ data }) => setCategories(data));
  // };

  // useEffect(() => {
  //   fetchFranchiseList();
  // }, []);

  // Initiate from Redux
  const { franchisePage, status } = props;

  // Handle Lazy Loading & Errors
  if (franchisePage.error || status === 404 || isEmpty(fullPackageObj)) {
    return (
      <section className="best-workplaces-for-innovators-page">
        <article className="best-workplaces-for-innovators__main--error-pg">
          <NotFound />
        </article>
      </section>
    );
  }

  if (franchisePage.isLoading) {
    return (
      <section className="best-workplaces-for-innovators-page">
        <article className="best-workplaces-for-innovators__main">
          <LoadingSpinner />
        </article>
      </section>
    );
  }

  const finalCategories = reduceExternalData(categories);

  return (
    <PackageContainer>
      <section className="metaSection">
        <Section bgColor="#ffffff" maxWidth={1000}>
          <h1 className="metaSection__title">{packageTitle}</h1>
          <div
            className="metaSection__excerpt"
            dangerouslySetInnerHTML={{ __html: packageExcerpt }}
          />
        </Section>
      </section>
      <section className="heroSection">
        <div className="heroImage">
          <Section bgColor="#ffffff" maxWidth={1000}>
            <div className="heroImage__desktop">
              <Image image={featureImageDesktop} />
            </div>
            <div className="heroImage__mobile">
              <Image image={featureImageMobile} />
            </div>
          </Section>
        </div>
      </section>
      <section className="introSection">
        <Section bgColor="#ffffff" maxWidth={1000}>
          <div className="introText">
            <Text>
              {blurbImage && (
                <Image image={blurbImage} width="85px" float="left" />
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: franchiseBlurb
                }}
              />
            </Text>
          </div>
        </Section>
      </section>
      <section className="carouselSection">
        <Section bgColor="#ffffff" maxWidth={900}>
          <QuickCarousel list="nbtt-2021" slides={carousel.slides} />
        </Section>
      </section>
      <section className="categorySection">
        {Object.keys(finalCategories).map((categoryName, index) => (
          <div
            key={`category-section-${index}`}
            className="categorySection__category"
          >
            <Section bgColor="#ffffff" maxWidth={1000}>
              <CategoryAndArticles
                icon={finalCategories[categoryName][0]['category-icon']}
                category_name={categoryName}
                category_url={finalCategories[categoryName][0].category_links}
                articles={finalCategories[categoryName].map(article => ({
                  hed: article['Company Name'],
                  dek: article['For Line'],
                  url: article.URL
                }))}
              />
            </Section>
            {index % 2 === 0 && <div>{interstitial}</div>}
          </div>
        ))}
      </section>
      <section className="contributorSection">
        <Section bgColor="#ffffff" maxWidth={1000}>
          <div dangerouslySetInnerHTML={{ __html: contributorsBlock }} />
        </Section>
      </section>
      <section className="footerSection">
        <div className="footerUnitWrapper">
          <AdContainer type="break" />
        </div>
      </section>
      <StickyBar
        hideTopBorder={true}
        slug="next-big-things-in-tech-2021"
        externalData={[finalCategories]}
        location="lander"
        post={{
          id: 'next-big-things-in-tech',
          slug: '',
          title: get(fullPackageData, 'data.acf.title', 'Fast Company')
        }}
      />
    </PackageContainer>
  );
};

// SSR Loading of data via Redux
NextBigThingsLayout.loadData = store => loadData(store);

function mapStateToProps(state = {}) {
  return {
    status: state.status.code,
    franchisePage: get(state, 'franchisePage') || {
      error: true
    },
    config: state.config
  };
}

NextBigThingsLayout.propTypes = {
  status: PropTypes.number.isRequired,
  franchisePage: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
  }).isRequired
};

export default withRouter(connect(mapStateToProps)(NextBigThingsLayout));
